import './App.css';
import List from './components/features/list'
function App() {

  return (
    <div className="App">
      <List/>
    </div>
  );
}

export default App;
